import type { ItemModel, DataItemModel } from 'o365.modules.DataObject.Types.ts';
import type DataObject from 'o365.modules.DataObject.ts';

import { ref, watch, onMounted, onUnmounted } from 'vue';

export type ScrollItem<T extends ItemModel = ItemModel> = {
    item?: DataItemModel<T>,
    /** Index of the item in the source data */
    index: number,
    /** The array index of the scroll item (value is from 0 to itemsToRender) */
    _index: number,
    pos: number,
    rowHeight: number,
    isLoading: boolean,
};

export default function useVirtualScroll<T extends ItemModel = ItemModel>(pOptions: VirtualScrollOptions<T>) {
    const scrollData: Ref<ScrollItem<T>[]> = ref([]);

    /** Type guard function for options dataRef */
    function isRefData<T extends ItemModel = ItemModel>(data: DataItemModel<T>[] | Ref<DataItemModel<T>[]>): data is Ref<DataItemModel<T>[]> {
        return !Array.isArray(data);
    }

    const sourceData = pOptions.dataRef;
    const dataIsRef = isRefData(sourceData);

    /** Total height of all rows */
    const totalHeight: Ref<number> = ref(0);
    const rowHeights: { height: number; pos: number }[] = [];
    const currentPosition = ref(0);

    const options = {
        itemSize: pOptions.itemSize ?? 34,
        itemsToRender: pOptions.itemsToRender ?? 50,
        staticRenderCount: pOptions.staticRenderCount ?? false,
        dynamicLoading: pOptions.dataObject?.dynamicLoading,
        preloadOnePage: pOptions.preloadOnePage ?? false,
        buffer: pOptions.buffer ?? 2,
        getRowHeight: (pOptions.getRowHeight ?? (() => pOptions.itemSize ?? 34)),
        horizontal: pOptions.horizontal ?? false
    };

    const variableSizes = pOptions.getRowHeight != null;
    let scrollContainer: HTMLElement | null = null;
    let scrollDirection: 'start' | 'end' = 'end';
    let prevScroll = 0;
    let scrollEndDebounce: number | null = null;


    let startFrom = 0;
    let prevDataLength = 0;
    let prevStart: number | null = null;
    let cancelDataLoaded: (() => void) | null = null;
    let cancelWatcher: (() => void) | null = null;
    let dynamicLoadingEnabled = false;
    function updateWatcher(pSkipDynamicLoading = false) {
        if (cancelWatcher) { cancelWatcher(); cancelWatcher = null; }
        if (cancelDataLoaded) { cancelDataLoaded(); cancelDataLoaded = null; }
        if (!pSkipDynamicLoading && options.dynamicLoading?.enabled) {
            cancelDataLoaded = pOptions.dataObject!.on('DynamicDataLoaded', (pClear) => {
                if (pClear) {
                    startFrom = 0;
                    currentPosition.value = 0;
                    if (scrollContainer) { scrollContainer.scrollTop = 0; }
                }
                setItemsToRender(true);
            });
            dynamicLoadingEnabled = true;
        } else if (pOptions.watchTarget) {
            cancelWatcher = watch(pOptions.watchTarget, () => { setItemsToRender(true); })
            dynamicLoadingEnabled = false;
        } else {
            cancelWatcher = watch(pOptions.dataRef, () => { setItemsToRender(true); })
            dynamicLoadingEnabled = false;
        }
    }

    if (options.dynamicLoading) {
        watch(() => options.dynamicLoading?.enabled, () => { updateWatcher(); });
    }
    updateWatcher();

    /**
     * Get the total length of the data used in this virtual scroll either 
     * from DataObject or the provided data ref
     */
    function getDataLength() {
        if (dynamicLoadingEnabled && options.dynamicLoading?.enabled) {
            return options.dynamicLoading.dataLength;
        } else {
            return dataIsRef ? sourceData.value.length : sourceData.length;
        }
    }

    /** Get item by index either from source data or dynamicLoading */
    function getItem(pIndex: number) {
        if (dynamicLoadingEnabled && options.dynamicLoading?.enabled) {
            return options.dynamicLoading.getItem(pIndex);
        } else {
            return dataIsRef ? sourceData.value[pIndex] : sourceData[pIndex];
        }
    }

    function handleScroll(e: MouseEvent) {
        scrollContainer = (e.target as HTMLElement);
        scrollDirection = (options.horizontal ? scrollContainer.scrollLeft : scrollContainer.scrollTop) < prevScroll
            ? 'start'
            : 'end';
        if (scrollEndDebounce) { window.clearTimeout(scrollEndDebounce); }
        scrollEndDebounce = window.setTimeout(() => { onScroll(); }, 5);
    }

    function onScroll() {
        if (scrollContainer == null) { return; }
        prevScroll = options.horizontal ? scrollContainer.scrollLeft : scrollContainer.scrollTop;
        let position = variableSizes
            ? binarySearchForIndex(prevScroll)
            : Math.round(prevScroll / options.itemSize);
        // let position = binarySearchForIndex(prevScroll);
        position = position > 0 ? position - 1 : position;
        startFrom = position;
        currentPosition.value = startFrom;
        updateItems();
    }

    /** Update the number of items to render */
    function updateItemRenderCount() {
        if (options.staticRenderCount) { return; }
        if (scrollContainer) {
            if (pOptions.elementRef.value) { scrollContainer = pOptions.elementRef.value; }
            const clientSize = options.horizontal ? scrollContainer.clientWidth : scrollContainer.clientHeight;
            options.itemsToRender = variableSizes
                ? getItemCountForSize(startFrom, clientSize) + options.buffer
                : Math.round(clientSize / options.itemSize) + options.buffer;
            if (options.itemsToRender > getDataLength()) {
                options.itemsToRender = getDataLength();
            }
            if (scrollContainer.offsetParent == null && clientSize === 0 && getDataLength() > options.itemsToRender) {
                options.itemsToRender = getDataLength() > options.itemsToRender ? options.itemsToRender : getDataLength();
            }
        } else {
            if (pOptions.elementRef.value) {
                scrollContainer = pOptions.elementRef.value;
                updateItemRenderCount();
                return;
            } else {
                options.itemsToRender = getDataLength() > options.itemsToRender ? options.itemsToRender : getDataLength();
            }
        }

        options.itemsToRender = Math.min(options.itemsToRender + 1, getDataLength());
        if (options.itemsToRender < scrollData.value.length) {
            scrollData.value.sort((a, b) => a.index - b.index);
            scrollData.value.splice(options.itemsToRender, scrollData.value.length);
            scrollData.value.forEach((item, index) => item._index = index);
        }
    }

    function updateItems(pForceUpdate?: boolean) {
        updateItemRenderCount();

        //let start = scrollDirection === 'top' ? startFrom - options.buffer : startFrom;
        let start = startFrom;
        let end = start + options.itemsToRender;

        if (end > getDataLength()) {
            start -= end - getDataLength();
            end = start + options.itemsToRender;
        }

        if (!pForceUpdate && prevStart === start) { return; }
        prevStart = start;
        const unusedIndexes: number[] = [];
        scrollData.value.forEach((item, index) => {
            if (item.index < start || item.index >= end) {
                unusedIndexes.push(index);
            }
        });
        if (variableSizes && scrollData.value.length < options.itemsToRender && getDataLength() >= options.itemsToRender) {
            for (let i = scrollData.value.length; i < options.itemsToRender; i++) {
                scrollData.value.push({
                    _index: i,
                    index: 0,
                    pos: 0,
                    rowHeight: 0,
                    isLoading: true
                });
                unusedIndexes.push(i);
            }
        }

        unusedIndexes.forEach((unusedIndex, index) => {
            const item = scrollData.value[unusedIndex];
            if (scrollDirection === 'end') {
                item.index = start + scrollData.value.length - unusedIndexes.length + index;
            } else {
                item.index = start + index;
            }
            item.item = getItem(item.index);
            if (variableSizes) {
                item.rowHeight = getRowHeightByIndex(item.index);
            }

            if (dynamicLoadingEnabled && options.dynamicLoading?.enabled) {
                if (item.item != null) {
                    item.isLoading = Object.keys(item.item).length === 0;
                }
            } else {
                item.isLoading = item.item == null;
            }
            item.pos = variableSizes
                ? getPosByIndex(item.index)
                : item.index * options.itemSize;
        });

        if (dynamicLoadingEnabled && options.dynamicLoading?.enabled) {
            //options.dynamicLoading.pageSize = options.itemsToRender;
            options.dynamicLoading.currentStart = start;
        }

        if (options.preloadOnePage) {
            end += options.itemsToRender;
        }

        if (end >= getDataLength() && dynamicLoadingEnabled && options.dynamicLoading?.enabled) {
            if (pOptions.dataObject?.rowCount == null) {
                options.dynamicLoading.loadNextPage();
            }
            // TOOD(Augustas): Next page loading at end check
        }
    }

    /** Populate scroll data */
    function populateScrollData(pDataChanged: boolean) {
        if (scrollContainer?.scrollTop === 0) {
            startFrom = 0;
            currentPosition.value = 0;
        }

        if (prevDataLength > getDataLength()) {
            startFrom = 0;
            currentPosition.value = 0;
        }

        if (scrollContainer && scrollContainer.scrollTop > 0 && startFrom === 0) {
            scrollContainer.scrollTop = 0;
        }

        if (!pDataChanged && scrollData.value.length === options.itemsToRender) {
            updateItems(true);
        } else {
            const prevLength = scrollData.value.length;
            scrollData.value.splice(0, scrollData.value.length);
            for (let i = 0; i < options.itemsToRender; i++) {
                const item = getItem(i + startFrom);
                scrollData.value.push({
                    _index: i,
                    index: i + startFrom,
                    item: item,
                    pos: variableSizes
                        ? getPosByIndex(i + startFrom)
                        : (i + startFrom) * options.itemSize,
                    rowHeight: variableSizes
                        ? getRowHeightByIndex(i + startFrom)
                        : options.itemSize,
                    isLoading: !item
                });
            }

            if (prevLength !== scrollData.value.length || startFrom === 0) {
                updateItems(true);
            }
        }
    }

    function setItemsToRender(pDataChanged: boolean) {
        if (pDataChanged && variableSizes) {
            getAllRowsHeights();
        }
        updateItemRenderCount();
        populateScrollData(pDataChanged);
    }

    function getAllRowsHeights() {
        rowHeights.splice(0, rowHeights.length);
        let newTotalHeight = 0;
        const dataArray = dataIsRef ? sourceData.value : sourceData;
        dataArray.forEach((row, index) => {
            const height = options.getRowHeight(row);
            rowHeights[index] = { height, pos: newTotalHeight };
            newTotalHeight += height;
        });
        totalHeight.value = newTotalHeight;
    }

    function getRowHeightByIndex(pIndex: number) {
        return rowHeights[pIndex]?.height ?? options.itemSize;
    }
    function getPosByIndex(pIndex: number) {
        return rowHeights[pIndex]?.pos ?? options.itemSize * pIndex;
    }

    /** Binary search for row index from scroll position */
    function binarySearchForIndex(pPosition: number) {
        let low = 0;
        let high = rowHeights.length;
        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const midPosition = rowHeights[mid].pos;

            if (midPosition === pPosition) {
                return mid;
            } else if (midPosition < pPosition) {
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }

        return low;
    }

    /** Get how many items would fit into the provided size from the provided index */
    function getItemCountForSize(pStart: number, pSize: number) {
        let count = 0;
        let accumulatedSize = 0;
        for (let i = pStart; i < rowHeights.length; i++) {
            accumulatedSize += rowHeights[i].height;
            if (accumulatedSize <= pSize) {
                count++;
            }
            if (accumulatedSize > pSize) {
                return count;
            }
        }
        return count;
    }

    function updateRowHeight(pIndex: number, pSize: number) {
        const prevHeight = rowHeights[pIndex].height;
        rowHeights[pIndex].height = pSize;
        for (let i = pIndex + 1; i < rowHeights.length; i++) {
            rowHeights[i].pos -= prevHeight + pIndex;
        }
    }

    onMounted(() => {
        if (pOptions.elementRef.value) {
            scrollContainer = pOptions.elementRef.value;
            setItemsToRender(true);
        }
        window.addEventListener("resize", setItemsToRender);
    });

    onUnmounted(() => {
        window.removeEventListener("resize", setItemsToRender);
        if (cancelDataLoaded) {
            cancelDataLoaded();
        }
    });

    return { scrollData, handleScroll, updateData: setItemsToRender, getRowHeightByIndex, getPosByIndex, totalHeight, updateRowHeight, updateWatcher, currentPosition };
}

export type VirtualScrollOptions<T extends ItemModel = ItemModel> = {
    /** Ref of the data array from which this virtual scroll will select items to render */
    dataRef: DataItemModel<T>[] | Ref<DataItemModel<T>[]>,
    /** Ref of the container element for this virutal list */
    elementRef: Ref<HTMLElement>,
    /** Height of a virtual item */
    itemSize?: number,
    /** Number of items to render at a time */
    itemsToRender?: number,
    /** Don't recalculate items to render count based on the scroll contianer size */
    staticRenderCount?: boolean,
    /** Count of buffer items for scrolling */
    buffer?: number,
    /** Optional DataObject for dynamic loading support */
    dataObject?: DataObject<T>,
    /** Load next page once on initialization */
    preloadOnePage?: boolean,
    /** Optional watch target override */
    watchTarget?: any,
    /** Variable sizes per row */
    getRowHeight?: (row: DataItemModel<T>) => number,
    /** When true the virtual scroll will be horizontal instead of vertical */
    horizontal?: boolean;
};

// Helper types
type Ref<T> = { value: T };